<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-link class="brand-logo" href="/">
        <!-- <vuexy-logo /> -->
        <!-- <h2 class="brand-text text-primary ml-1">Vuexy</h2> -->
        <b-img
        :src="require('@/assets/images/avatars/13-small.png')"
        style="width: 30px; height: 30px"
          alt="logo"
          class=""
        ></b-img>
        <h2 class="brand-text text-primary ml-1">Optimal</h2>
      </b-link>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Halaman tidak ditemukan 🕵🏻‍♀️</h2>
        <p class="mb-2">
          Uups! 😖 URL yang diminta tidak ditemukan di website ini.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          Kembali ke Halaman Awal
        </b-button>

        <!-- image -->
        <b-img fluid :src="imgUrl" alt="Error page" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import store from "@/store/index";

export default {
  components: {},
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/error-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
